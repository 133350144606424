import * as React from "react";
import {CircleV2} from "../components/pcb";
import {Button, Col, Container, Row} from "react-bootstrap";
import linkedin from "../images/linkedin.svg";
import github from "../images/github.svg";

const AboutPage = ()=>{
    return (<div>
        {/*<div style={{zIndex:-100,  position:"absolute", top: 0, left: 0, width:"100vmax"}}>
        <CircleV2 style = {{width:"100vh"}}/>
        </div>*/}
        <Container>
            <Row  className={"justify-content-md-center"}>
                <Col lg={12}>
                    <h1  style={{ textAlign: "center" }} className={"display-1"}>Hi, I'm Bill</h1>
                </Col>
            </Row>
            <Row gx={5}>
                <Col sm={2} className={"d-none d-lg-block"}>
                </Col>
                <Col md={8}>
                    <p>
                        I have had a great career working my way through leadership while keeping my technical skills in tact.
                    </p>
                </Col>
                <Col sm={2} className={"d-none d-lg-block"}>
                </Col>
            </Row>
            <Row className={"justify-content-center"}>
                <Col sm={2} className={"d-none d-lg-block"}>
                </Col>
                <Col lg={6} md={12}>
                    <p>Engineering leader with over twenty years of experience in design and development.</p>
                    <ul>
                        <li>Passionate technical leader who can invent and execute solutions amid chaos</li>
                        <li>Designed and developed the software, electronics and firmware for dozens of products</li>
                        <li>Wrote the software running on millions of devices in the field -- with zero recalls</li>
                        <li>PhD/MSEE Published and Presented by ACM and IEEE</li>
                        <li>All three degrees earned while working full time</li>
                    </ul>
                </Col>
                <Col sm={4} className={"d-none d-lg-block"}>
                    <h3 className={"display-5"} style={{textAlign:"left"}}>20+ Years Exp.</h3>
                    <h3 className={"display-5"} style={{textAlign:"left"}}>Software</h3>
                    <h3 className={"display-5"} style={{textAlign:"left"}}>Firmware</h3>
                    <h3 className={"display-5"} style={{textAlign:"left"}}>Hardware</h3>
                </Col>
            </Row>
            <Row>
                <h3  style={{ textAlign: "center" }} className="display-3">Academics</h3>
            </Row>
            <Row>
                <Col sm={4} className={"d-none d-lg-block"}>
                    <h3 className={"display-5"} style={{textAlign:"right"}}>PHD</h3>
                    <h3 className={"display-5"} style={{textAlign:"right"}}>MSEE</h3>
                    <h3 className={"display-5"} style={{textAlign:"right"}}>Comp Eng</h3>
                </Col>
                <Col lg={8} md={12}>
                    <p className="lead">Doctorate in Engineering - Cleveland State University 2011<br/>
                        Dissertation: <a href={"http://rave.ohiolink.edu/etdc/view?acc_num=csu1305319887"}>Simplifying Embedded System Development through Whole-Program Compilers</a></p>
                    <p><a href={"https://www.csuohio.edu/engineering/sites/csuohio.edu.engineering/files/media/ece/documents/McCartney%20William%20P.pdf"}>Masters in Electrical Engineering</a> - Cleveland State University 2006</p>
                    <p>Bachelors in Computer Engineering - Cleveland State University 2005</p>
                    <p>Publications: <a href={"https://ieeexplore.ieee.org/author/38181880700"}>IEEE</a>&nbsp;&nbsp;&nbsp;<a href={"https://dl.acm.org/profile/81320492362"}>ACM</a></p>
                </Col>
            </Row>
            <Row style={{textAlign:"center"}}>
                <Row md={2}>
                    <Col>
                        <a href={"https://www.linkedin.com/in/billmccartney/"}>LinkedIn Profile<img height={"50px"} src={linkedin} alt={"LinkedIn"} /></a>
                    </Col>
                    <Col>
                        <a href={"https://github.com/billmccartney"}>Github Profile<img height={"50px"} src={github} alt={"GitHub"} /></a>
                    </Col>
                </Row>
            </Row>
            <Row style={{textAlign:"center"}}>
                <Col md={8}>
                    <Button href={"/"}>Go Back</Button>
                </Col>
            </Row>
        </Container>
    </div>);
    /*
    return (
        <main style={{backgroundColor:"#000000"}}>
            <Button onClick={()=>{alert("test");}} >test</Button>
        <title>About Us</title>
            <h1>About Bill</h1>
            <PcbAnimated style = {{width:"40vh"}}/>
            <CircleV2 style = {{width:"100vh"}}/>

        </main>
    );*/
};

export default AboutPage